import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { parseSeo } from '../helpers/seo'
import Layout from '../views/Layout'
import SEO from '../views/Seo'
import { PageContent } from '../components/PageContent'
import { WpBlocksGenerator } from '../helpers/wpBlocksGenerator'

const NotFoundPage = () => {
  const staticQuery = useStaticQuery(graphql`
    query NotFoundQuery($lang: WordPress_LanguageCodeFilterEnum) {
      wordpress {
        specificPagesSettings(language: $lang) {
          data {
            notFoundPage {
              ...Single_Page
            }
          }
        }
      }
    }
  `)

  const page = staticQuery.wordpress.specificPagesSettings.data.notFoundPage
  const { blocks = [], seo } = page

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <PageContent>
        <WpBlocksGenerator blocks={blocks} post={page} />
      </PageContent>
    </Layout>
  )
}

export default NotFoundPage
